<template>
  <cs-page-setting ref="page" name="tel">
    <a-form-model
      ref="form"
      :model="formModel"
      :rules="formRules"
      class="mx-auto"
      @submit.native.prevent
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-form-model-item prop="mobile" label="手机号码">
        <a-input
          v-model.trim="formModel.mobile"
          placeholder="请输入手机号"
          :maxLength="11"
        >
        </a-input>
      </a-form-model-item>

      <a-form-model-item prop="code" label="短信验证码">
        <a-input
          v-model="formModel.code"
          :maxLength="6"
          placeholder="请输入短信验证码"
        >
          <span slot="addonAfter" class="btnSendCode">
            <cs-countdown
              text="发送验证码"
              :validate="validateCode"
              v-slot="{ time }"
              >{{ time }}s后重试</cs-countdown
            >
          </span>
        </a-input>
      </a-form-model-item>
      <p class="tips">
        如果1分钟内没有收到验证短信，请重新获取，验证码半小时内有效
      </p>
      <a-button
        type="primary"
        block
        style="margin-top: 30px"
        v-click.timeout="2"
        @click="nextClick"
        >确定</a-button
      >
    </a-form-model>
  </cs-page-setting>
</template>
<script>
export default {
  data() {
    return {
      formModel: {
        mobile: "",
        code: "",
      },
      formRules: {},
    };
  },
  methods: {
    // 获取验证码
    async validateCode() {
      const { mobile } = this.formModel;
      if (!mobile) {
        throw new Error("请输入手机号");
      }
      const { page } = this.$refs;
      const {
        code,
        msg,
      } = await this.api.service.uac_oapi_msg_portalSendMsgByLogin(
        {
          authCode: page.authCode,
          bizType: 14,
          mobile,
          source: 4,
        },
        {}
      );
      await this.api.toast({ code, msg });
    },
    nextClick() {
      const { mobile, code } = this.formModel;
      const { page } = this.$refs;
      this.api.service
        .uac_oapi_sso_user_updateUserMobile(
          { authCode: page.authCode, code, mobile },
          {}
        )
        .then(async ({ code, msg }) => {
          await this.api.toast({ code, msg }, 0);
          this.$refs.page.next();
        });
    },
  },
};
</script>
<style lang="less" scoped>
</style>
